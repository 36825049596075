<template>
  <div>
    <div class="section-title">
      <h3>{{ type.toString() === '1' ? 'Toma' : 'Salida' }} de muestras</h3>
    </div>
    <div class="content">
      <form @submit.prevent="onSubmit">
        <b-field label="Por orden" v-if="type.toString() === '1'">
          <b-checkbox v-model="byOrden"/>
        </b-field>
        <b-field label="Código de Muestra">
          <b-input v-model="sample" type="number" step="1"/>
        </b-field>
      </form>
    </div>
    <div class="content">
      <form @submit.prevent="onSend">
        <b-button type="is-primary is-fullwidth" native-type="submit">Enviar Lote</b-button>
      </form>
    </div>
    <div class="content">
      <div class="activity-items">
        <b-table
            :data="data"
            striped
            :loading="loading"
            :row-class="getRowClass"
        >
          <b-table-column field="action" label="Acciones" v-slot="props">
            <b-button
                @click="onDropItem(props.row.code)">
              <b-icon
                  icon="window-close"
                  size="is-small">
              </b-icon>
            </b-button>
          </b-table-column>
          <b-table-column field="code" label="Código" searchable sortable v-slot="props">
            {{ props.row.code }}
          </b-table-column>

          <b-table-column field="fullname" label="Paciente" searchable sortable v-slot="props">
            {{ props.row.patient.full_name }}
          </b-table-column>

          <b-table-column field="birthday" label="Fecha de Nacimiento" sortable v-slot="props">
            {{ props.row.patient.birthday }}
          </b-table-column>

          <b-table-column field="bio_material.name" label="Contenedor" searchable sortable v-slot="props">
            {{ props.row.container.name }}
          </b-table-column>


          <b-table-column field="bio_material.name" label="Material Biologico" searchable sortable v-slot="props">
            {{ props.row.bio_material.name }}
          </b-table-column>

          <b-table-column field="status" label="Estado" sortable v-slot="props">
            {{ props.row.status }}
          </b-table-column>

        </b-table>
      </div>
    </div>
    <div class="content">
      <form @submit.prevent="onSend">
        <b-button type="is-primary is-fullwidth" native-type="submit">Enviar Lote</b-button>
      </form>
    </div>
    <div class="content">
      <router-link class=""
                   :to="{name:'io-samples'}">
        Regresar
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "Create",
  props: {
    type: Number | String
  },
  data() {
    return {
      sample: '',
      loading: false,
      data: [],
      columns: [
        {
          field: 'code',
          label: 'Código',
          searchable: true,
        },
        {
          field: 'patient.full_name',
          label: 'Paciente',
          searchable: true,
        },
        {
          field: 'patient.birthday',
          label: 'Fecha de Nacimiento',
          searchable: true,
        },
        {
          field: 'container.name',
          label: 'Contenedor',
          searchable: true
        },
        {
          field: 'bio_material.name',
          label: 'Material Biológico',
          searchable: true
        },
        {
          field: 'status',
          label: 'Estado'
        }
      ],
      apiUrl: 'lots',
      errors: {},
      byOrden: true,
      orderNew: ''
    }
  },
  computed: {
    storegeKey() {
      const extra = this.type.toString() !== '2' ? '.1' : '';
      return 'io-samples.lot'  + extra;
    }
  },
  mounted() {
    this.prepareComponent();
  },
  methods: {
    prepareComponent() {
      let data = localStorage.getItem(this.storegeKey);
      if (data) {
        try {
          data = JSON.parse(data);
          if (Array.isArray(data)) {
            this.data = data;
          }
        } catch {
        }
      }
    },
    async onSubmit() {
      this.errors = {};
      if (this.sample) {
        this.loading = true;
        if (this.data.filter((item) => {
          return item.code.toString() === this.sample.toString()
        }).length === 0) {
          this.fetchData('get', `sample-exists/${this.sample}/${this.type}`).then(response => {
            console.log(response);
            let allow = true;
            if(this.type.toString() === '1' && this.byOrden){
              if(!this.orderNew){
                this.orderNew  = response.sample.order.order_new
              }
              if(response.sample.order.order_new !== this.orderNew){
                allow = false;
              }
              if(!response.active){
                this.$buefy.dialog.alert({
                  title: 'Orden Cancelada',
                  message: "La muestra indicada pertenece a una orden cancelada"
                });
              }
            }
            if(allow){
              this.data.unshift(Object.assign({code: this.sample, status: 'Pendiente'}, response.sample));
              this.data = Object.assign([], this.data);
              this.sample = '';
              localStorage.setItem(this.storegeKey, JSON.stringify(this.data));
              if(this.$alfalab.isIOSamplesFast(this, this.type.toString())){
                this.onSend();
              }
            }else{
              this.$toast.error('Solo se pueden agregar muestras de una misma orden');
            }
          }).catch(error => {
            this.$alfalab.showError(error)
          }).finally(() => {
            this.loading = false;
          })
        } else {
          console.error('Ese código ya está registrado para ser enviado');
          this.$toast.error("El código ya está registrado para ser enviado");
          this.loading = false;
        }
      } else {
        this.$toast.error("El código de la muestra es requerido");
      }

    },
    async onSend() {
      this.errors = {};
      if (this.data.length > 0) {
        this.loading = true;
        this.storeData({
          type: this.type > 2 ? 2 : this.type,
          samples: this.data
        }).then(response => {
          console.log(response);
          this.data = [];
          localStorage.removeItem(this.storegeKey);
          this.$toast.success('Muestras registradas con éxito');
        }).catch(errors => {
          this.$alfalab.showError(errors);
          this.errors = errors.errors;
        }).finally(() => {
          this.loading = false;
          this.orderNew = '';
        })
      } else {
        this.$toast.error('Debe capturar al menos un código de muestra')
      }
    },
    onDropItem(code) {
      const pos = this.data.findIndex((item) => {
        return item.code === code
      });
      const nuevo = this.data.slice(0);
      nuevo.splice(pos, 1);
      this.data = nuevo;
      localStorage.setItem(this.storegeKey, JSON.stringify(this.data));
      this.errors = {}
      if(this.data.length === 0){
        this.orderNew = '';
      }
    },
    getRowClass(row, index){
      for(const key in this.errors) {
        if (key.toString().startsWith('samples')) {
          const id = key.toString().split('.')[1]
          if (index.toString() === id.toString()) {
            return 'is-danger';
          }
        }
      }
    }
  }
}
</script>

<style scoped>
</style>
